.my_header {
    width: 100%;
    height: 30px;
    background-color: bisque;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.main_page {
  display: flex;
  height: 100vh;
  width: 1200px;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
}
.login-page {
    display: flex;
    align-items: center;
    justify-content: center;

}
.my-login-form {
    width: 360px;
    height: 250px;
    border: 1px solid lightgrey;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
}
.login-name {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.password {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 80px;
    padding-right: 80px;
    align-items: center;
}
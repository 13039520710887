.members-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.members-main-header {
    font-family: 'Montserrat', sans-serif;
}
.create-member-page {
    display: flex;
    align-items: center;
    justify-content: center;
}
.member-form {
    width: 360px;
    height: 400px;
    border: 1px solid lightgrey;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
}
.form-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.submit-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 70px;
    padding-right: 70px;
}
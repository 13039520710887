.member-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between
}
.content {
    height: 700px;
}
.Modal-dialog {
    /* position: absolute; */
    /* top: 40vh;
    left: 40vw;
    right: 40vw;
    bottom: 40vh; */
    width: 300px;
    height: 210px;
    margin-top: 300px;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(250, 252, 253, 0.699);
    z-index: 0;
    
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(249, 248, 245, 0.888);
    opacity: 1;
    z-index: 10;
}
.warning-title {
    color: red;
    font-size: 32px;
    display: flex;
    justify-content: center;
}
.warning-text {
    display: flex;
    justify-content: center;
    padding-left: 10px;
}
.modal-content {
    width: 280px;
    height: 190px;
    border: 1px solid bisque;
    border-radius: 10px;
    padding: 10px;
}
.buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 80px;
    padding-right: 80px;
    align-items: center;
}
.table-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
}
.search-field {
    padding-left: 15px;
    display: flex;
    width: 280px;
    justify-content: space-between;
}
#delete-button {
    background-color: red;
    width: 25px;
    height: 20px;
    border-radius: 5px;
}
#modify-button {
    background-color: orange;
    width: 25px;
    height: 20px;
    border-radius: 5px;
}
.crud-modal-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
  }
  .crud-modal-wrapper__background {
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1000;
  }
  
  .crud-modal-wrapper__modal {
    background-color: white;
    border-radius: 5px;
    z-index: 1001;
    position: relative;
    padding: 1rem;
    margin: 3rem auto 0 auto;
    top: 1rem;
    display: inline-block;
  }
  
  .crud-modal-wrapper__title {
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 0.8rem;
    font-size: 1.3rem;
  }
  
  .crud-modal-form {
    padding: 1rem;
  }
  
  .crud-modal-form__field-error {
    color: #fff;
    background-color: #e81b1b;
    border-radius: 3px;
    padding: 0.3rem 0.8rem;
    text-align: left;
    margin-bottom: 0.5rem;
    position: relative;
    margin-top: -0.6rem;
  }
  
  .crud-modal-form__field-error:before {
    content: "";
    background-color: transparent;
    display: block;
    position: absolute;
    top: -0.3rem;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #e81b1b;
  }
  
  .crud-modal-form__message {
    padding: 0 0 1.2rem 0;
    font-size: 1.1rem;
    text-align: left;
  }
  
  .crud-modal-form button {
    margin-top: 0.5rem;
    min-width: 250px;
  }
  
  .crud-modal-form input,
  .crud-modal-form select,
  .crud-modal-form textarea {
    border-radius: 5px;
    height: 30px;
    border: 1px solid #ccc;
    min-width: 250px;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    padding-left: 0.6rem;
  }
  
  .crud-modal-form textarea {
    min-height: 70px;
    height: auto;
  }
  
  .crud-modal-form__label {
    color: #000;
    display: block;
    text-align: left;
    font-weight: 600;
  }
  
  .crud-button {
    background-color: #000;
    min-height: 1rem;
    color: #fff;
    border-radius: 3px;
    padding: 0.6rem 1rem;
    border: none;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .crud-button--circular {
    border-radius: 50%;
    padding: 0;
    width: 2rem;
    line-height: 2rem;
  }
  
  .crud-button--primary {
    background-color: #2185d0;
  }
  
  .crud-button--primary:hover {
    background-color: #1a6fb1;
    transition: all 1s;
  }
  
  .crud-button--positive {
    background-color: #21d05b;
  }
  
  .crud-button--positive:hover {
    background-color: #1da54a;
    transition: all 1s;
  }
  
  .crud-button--negative {
    background-color: #f51010;
  }
  
  .crud-button--negative:hover {
    background-color: #b80f0f;
    transition: all 1s;
  }
  
  .crud-table {
    background-color: transparent;
    border: 1px solid rgba(52, 145, 238, 0.15);
    border-collapse: collapse;
    box-shadow: 2px 2px 5px 0 #c7c7c7;
  }
  .crud-table__row {
  }
  .crud-table__header-cell {
    cursor: pointer;
    background: rgba(153, 158, 162, 0.05);
    padding: 0.5rem 1rem;
    color: rgba(0, 0, 0, 0.85);
    border-left: 1px solid rgba(34, 36, 38, 0.1);
    border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  }
  
  .crud-table__header {
  }
  
  .crud-table__body {
    background-color: #fff;
  }
  
  .crud-table__cell {
    background-color: white;
    padding: 0.5rem 1rem;
    border-left: 1px solid rgba(34, 36, 38, 0.1);
    border-bottom: 1px solid rgba(4, 21, 37, 0.1);
  }
  
  .crud-table__cell-label {
    display: none;
  }
  
  .crud-table__caption {
    background-color: white;
    padding: 0.8rem;
    margin: 1rem 0;
    box-shadow: 2px 2px 10px 0 #c7c7c7;
    font-size: 1.5rem;
    text-align: center;
    font-weight: 600;
  }
  
  .crud-table-pagination {
    display: block;
    margin-top: 1rem;
    margin: auto;
  }
  
  .crud-table-pagination::before {
    content: "";
    clear: both;
    display: block;
    margin-top: 2rem;
  }
  
  .crud-table-pagination__link,
  .crud-table-pagination__previous,
  .crud-table-pagination__next {
    color: #000;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color 0.3s;
    border: 1px solid #ddd;
    background-color: #fff;
    cursor: pointer;
    box-shadow: 2px 2px 5px 0 #c7c7c7;
    border-radius: 2px;
  }
  
  .crud-table-pagination__link--active {
    background-color: rgb(32, 132, 228);
    color: #fff;
    border: 1px solid rgb(26, 112, 197);
  }
  
  .crud-table-pagination__link:hover,
  .crud-table-pagination__previous:hover,
  .crud-table-pagination__next:hover {
    background-color: #3bbf5c;
    color: #fff;
    border-color: #28a93e;
  }
  
  .crud-table-query-builder {
    background-color: #fff;
    padding: 1rem;
    border-radius: 0.2rem;
    margin-bottom: 1rem;
    box-shadow: 2px 2px 10px 0 #c7c7c7;
  }
  
  .crud-table-query-builder select,
  .crud-table-query-builder input {
    border-radius: 5px;
    height: 30px;
    border: 1px solid #ccc;
    min-width: 150px;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    padding-left: 0.6rem;
  }
  
  .crud-label {
    display: inline-block;
    border: 1px solid #e5e5e5;
    padding: 0.48rem 1rem;
    border-radius: 0.2rem;
    background-color: #f9fafb;
    font-size: 1.1rem;
    margin-right: 0.5rem;
    margin-bottom: 0.7rem;
  }
  
  @media screen and (max-width: 600px) {
    .crud-table {
      display: block;
      min-width: 85vw;
      margin-top: 1rem;
      border-radius: 3px;
      overflow: hidden;
      margin-bottom: 2rem;
    }
  
    .crud-table__row,
    .crud-table__caption,
    .crud-table__header,
    .crud-table__body,
    .crud-table__cell {
      display: block;
      width: 100%;
    }
  
    .crud-table__caption {
      margin: 1rem 0;
      border-bottom: 1px solid #e6e6e6;
      max-width: 100%;
      display: block;
      width: auto;
    }
  
    .crud-table__header,
    .crud-table__header-cell {
      display: none;
    }
  
    .crud-table__cell {
      border-bottom: 0;
      border-left: 0;
    }
  
    .crud-table__cell:last-child {
      padding-bottom: 2rem;
    }
  
    .crud-table__row {
      border-bottom: 1px solid #e6e6e6;
    }
  
    .crud-table__row:not(:last-child) {
      margin-bottom: 2rem;
    }
  
    .crud-table__cell-label {
      display: block;
      font-size: 1.2rem;
      font-weight: bold;
      border-bottom: 1px dashed #e6e6e6;
      padding: 0.5rem;
      margin-bottom: 0.5rem;
      background: rgba(184, 187, 189, 0.05);
    }
  
    .crud-table-query-builder .crud-button--add {
      margin: 0 auto 2rem auto;
      display: block;
      border-radius: 50%;
      line-height: 3rem;
      width: 3rem;
      padding: 0;
      font-size: 1.6rem;
    }
  
    .crud-table-query-builder select,
    .crud-table-query-builder input {
      width: 100%;
      margin-bottom: 0;
    }
  }
  .App {
    font-family: sans-serif;
    text-align: center;
  }
  